import { Component } from '@angular/core';
import { ContainerComponent } from '../container/container.component';
import { RouterLink } from '@angular/router';
import { ButtonDirective } from '../../directives/button.directive';
import { AuthService } from '../../services/auth.service';
import { map } from 'rxjs';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'cs-call-to-action',
  standalone: true,
  imports: [CommonModule, ContainerComponent, ButtonDirective, RouterLink],
  templateUrl: './call-to-action.component.html',
  styleUrl: './call-to-action.component.scss',
})
export class CallToActionComponent {
  isAuthenticated$ = this.userService.isAuthenticated$;
  isProfileComplete$ = this.userService.isProfileComplete$;

  constructor(private userService: AuthService) {
  }
}
