import { ErrorHandler, Injectable } from '@angular/core';
import { ApmService } from './apm.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService extends ErrorHandler {
  constructor(private apmService: ApmService) {
    super();
  }

  override handleError(error: any): void {
    this.apmService.logException(error);
    super.handleError(error);
  }
}
