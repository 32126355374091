<header class="py-10">
  <cs-container>
    <nav class="relative z-50 flex justify-between">
      <div class="flex items-center md:gap-x-12">
        <a routerLink="/" aria-label="Home">
          <cs-logo />
        </a>
        <div class="hidden md:flex md:gap-x-6">
          <a csNavLink routerLink="/" csScrollTo="#testimonials">Testimonials</a>
          <a csNavLink routerLink="/" csScrollTo="#pricing">Pricing</a>
          <a csNavLink routerLink="/" csScrollTo="#faq">FAQs</a>
          <a csNavLink href="https://casuals.club/" target="club">
            Apparel
            <ng-icon name="heroArrowTopRightOnSquare" size="1.1rem" color="pink" class="align-text-top"></ng-icon>
          </a>
        </div>
      </div>
      <div class="flex items-center gap-x-5 md:gap-x-8">
        <div *ngIf="(isAuthenticated | async) !== true" class="hidden md:block">
          <a data-testid="btn-head-sign-in" csNavLink routerLink="/login">Sign in</a>
        </div>
        <button data-testid="btn-head-get-started" *ngIf="(isAuthenticated | async) !== true" csButton routerLink="/login" color="pink">
          <span> Get started <span class="hidden lg:inline">today</span> </span>
        </button>
        <a data-testid="btn-head-messages" *ngIf="isAuthenticated | async" class="message-icon relative text-pink-300 hover:text-pink-400" routerLink="/messages" (mouseenter)="hoverMessages = true" (mouseleave)="hoverMessages = false">
          <ng-icon *ngIf="!hoverMessages" name="heroEnvelope" size="2rem"></ng-icon>
          <ng-icon *ngIf="hoverMessages" name="heroEnvelopeOpen" size="2rem" class="relative -top-1"></ng-icon>
          <ng-icon name="heroHeartSolid" size="1.2rem" class="new-message text-pink-500 absolute -top-0.5 -right-1.5"></ng-icon>
        </a>
        <a *ngIf="isAuthenticated | async" class="user-icon" class="text-pink-300 hover:text-pink-400" routerLink="/my-profile">
          <ng-icon name="heroUser" size="2rem"></ng-icon>
        </a>
        <a *ngIf="(isAuthenticated | async) && canUpgrade()" csButton color="pink" routerLink="/" csScrollTo="#pricing">
          <ng-icon name="heroStarMini" size="1.0rem" class="mr-0.5"></ng-icon>
          Upgrade membership
        </a>
        <div class="-mr-1 md:hidden">
          <cs-mobile-nav />
        </div>
      </div>
    </nav>
  </cs-container>
</header>
