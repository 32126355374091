import { Routes } from '@angular/router';
import { AdminGuard } from './core/guards/admin.guard';
import { HomeComponent } from './features/home/home.component';
import { PageLayoutComponent } from './core/components/page-layout/page-layout.component';
import { AuthGuard } from './core/guards/auth.guard';
import { AuthReadyGuard } from './core/guards/auth-ready.guard';
import { ReadyGuard } from './core/guards/ready.guard';

export const routes: Routes = [
  {
    path: 'login-redirect',
    canActivate: [AuthGuard],
    loadComponent: () => import('./features/login-redirect/login-redirect.component').then((m) => m.LoginRedirectComponent),
  },
  {
    path: 'login',
    loadComponent: () => import('./features/login/login.component').then((m) => m.LoginComponent),
  },
  {
    path: 'register',
    loadComponent: () => import('./features/register/register.component').then((m) => m.RegisterComponent),
  },
  {
    path: '',
    component: PageLayoutComponent,
    children: [
      {
        path: '',
        canActivate: [ReadyGuard],
        component: HomeComponent,
      },
      {
        path: 'get-started',
        canActivate: [AuthReadyGuard],
        loadComponent: () => import('./features/get-started/get-started.component').then((m) => m.GetStartedComponent),
      },
      {
        path: 'get-verified',
        canActivate: [AuthReadyGuard],
        loadComponent: () => import('./features/get-verified/get-verified.component').then((m) => m.GetVerifiedComponent),
      },
      {
        path: 'my-profile',
        canActivate: [AuthReadyGuard],
        loadComponent: () => import('./features/my-profile/my-profile.component').then((m) => m.MyProfileComponent),
      },
      {
        path: 'my-account',
        canActivate: [AuthReadyGuard],
        loadComponent: () => import('./features/my-account/my-account.component').then((m) => m.MyAccountComponent),
      },
      {
        path: 'my-account/get-upgrade',
        canActivate: [AuthReadyGuard],
        loadComponent: () => import('./features/get-upgrade/get-upgrade.component').then((m) => m.GetUpgradeComponent),
      },
      {
        path: 'my-account/upgrade/:id',
        canActivate: [AuthReadyGuard],
        loadComponent: () => import('./features/purchase-upgrade/purchase-upgrade.component').then((m) => m.PurchaseUpgradeComponent),
      },
      {
        path: 'profile/:id',
        canActivate: [AuthReadyGuard],
        loadComponent: () => import('./features/profile/profile.component').then((m) => m.ProfileComponent),
      },
      {
        path: 'profile/:id/:view',
        canActivate: [AuthGuard],
        loadComponent: () => import('./features/profile/profile.component').then((m) => m.ProfileComponent),
      },
      {
        path: 'messages',
        canActivate: [AuthGuard],
        loadComponent: () => import('./features/messages/messages.component').then((m) => m.MessagesComponent),
      },
      {
        path: 'search',
        canActivate: [ReadyGuard],
        loadComponent: () => import('./features/search/search.component').then((m) => m.SearchComponent),
      },
      {
        path: 'locations/:slug',
        canActivate: [ReadyGuard],
        loadComponent: () => import('./features/locations/locations.component').then((m) => m.LocationsComponent),
      },
      {
        path: 'features/free-profile',
        loadComponent: () => import('./pages/features/free-profile/free-profile.component').then((m) => m.FreeProfileComponent),
      },
      {
        path: 'features/verified-profile',
        loadComponent: () => import('./pages/features/verified-profile/verified-profile.component').then((m) => m.VerifiedProfileComponent),
      },
      {
        path: 'features/lover-subscription',
        loadComponent: () => import('./pages/features/lover-subscription/lover-subscription.component').then((m) => m.LoverSubscriptionComponent),
      },
      {
        path: 'features/star-subscription',
        loadComponent: () => import('./pages/features/star-subscription/star-subscription.component').then((m) => m.StarSubscriptionComponent),
      },
      {
        path: 'legal/terms',
        loadComponent: () => import('./pages/legal/terms-of-use/terms-of-use.component').then((m) => m.TermsOfUseComponent),
      },
      {
        path: 'legal/privacy-policy',
        loadComponent: () => import('./pages/legal/privacy-policy/privacy-policy.component').then((m) => m.PrivacyPolicyComponent),
      },
      {
        path: 'legal/cookie-policy',
        loadComponent: () => import('./pages/legal/cookie-policy/cookie-policy.component').then((m) => m.CookiePolicyComponent),
      },
      {
        path: 'legal/safety',
        loadComponent: () => import('./pages/legal/safety/safety.component').then((m) => m.SafetyComponent),
      },
      {
        path: 'community/guidelines',
        loadComponent: () => import('./pages/community/guidelines/guidelines.component').then((m) => m.GuidelinesComponent),
      },
      {
        path: 'community/press',
        loadComponent: () => import('./pages/community/press/press.component').then((m) => m.PressComponent),
      },
      {
        path: 'community/blog',
        loadComponent: () => import('./pages/community/blog/blog.component').then((m) => m.BlogComponent),
      },
      {
        path: 'community/support',
        loadComponent: () => import('./pages/community/support/support.component').then((m) => m.SupportComponent),
      },
      {
        path: 'company/contact',
        loadComponent: () => import('./pages/company/contact/contact.component').then((m) => m.ContactComponent),
      },
      {
        path: 'company/jobs',
        loadComponent: () => import('./pages/company/jobs/jobs.component').then((m) => m.JobsComponent),
      },
      {
        path: 'company/ip',
        loadComponent: () => import('./pages/company/intellectual-property/intellectual-property.component').then((m) => m.IntellectualPropertyComponent),
      },
      {
        path: 'help',
        title: 'Help - Casuals',
        loadComponent: () => import('./pages/help/help.component').then((m) => m.HelpComponent),
      },
      {
        path: 'help/contact/:issueId',
        title: 'Contact - Help - Casuals',
        loadComponent: () => import('./pages/help/contact/contact.component').then((m) => m.ContactComponent),
      },
      {
        path: 'help/annual-discount',
        title: 'Annual Discount - Help - Casuals',
        loadComponent: () => import('./pages/help/annual-discount/annual-discount.component').then((m) => m.AnnualDiscountComponent),
      },
      {
        path: 'help/regional-pricing',
        title: 'Regional Pricing - Help - Casuals',
        loadComponent: () => import('./pages/help/regional-pricing/regional-pricing.component').then((m) => m.RegionalPricingComponent),
      },
      {
        path: 'help/payment-options',
        title: 'Payment Options - Help - Casuals',
        loadComponent: () => import('./pages/help/payment-options/payment-options.component').then((m) => m.PaymentOptionsComponent),
      },
      {
        path: 'admin/reports',
        title: 'Reports - Admin - Casuals',
        canActivate: [AdminGuard],
        loadComponent: () => import('./features/admin/reports/reports.component').then((m) => m.ReportsComponent),
      },
      {
        path: 'admin/reports/:id',
        title: 'Report ID - Reports - Admin - Casuals',
        canActivate: [AdminGuard],
        loadComponent: () => import('./features/admin/report/report.component').then((m) => m.ReportComponent),
      },
      {
        path: 'admin/users',
        title: 'Users - Admin - Casuals',
        canActivate: [AdminGuard],
        loadComponent: () => import('./features/admin/users/users.component').then((m) => m.UsersComponent),
      },
      {
        path: 'admin/users/:id',
        title: 'User Nickname - Users - Admin - Casuals',
        canActivate: [AdminGuard],
        loadComponent: () => import('./features/admin/user/user.component').then((m) => m.UserComponent),
      },
      {
        path: 'admin/locations',
        title: 'Locations - Admin - Casuals',
        canActivate: [AdminGuard],
        loadComponent: () => import('./features/admin/locations/locations.component').then((m) => m.LocationsComponent),
      },
      {
        path: 'admin/locations/:id',
        title: 'Location Name - Locations - Admin - Casuals',
        canActivate: [AdminGuard],
        loadComponent: () => import('./features/admin/location/location.component').then((m) => m.LocationComponent),
      },
      {
        path: 'admin/alerts/:id',
        title: 'Alerts - Admin - Casuals',
        canActivate: [AdminGuard],
        loadComponent: () => import('./features/admin/alerts/alerts.component').then((m) => m.AlertsComponent),
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];
