<button
  class="z-10 flex h-8 w-8 p-2 border rounded-full items-center justify-center ui-not-focus-visible:outline-none"
  [ngClass]="{ 'relative border-transparent': !open, 'fixed top-16 right-5 hover:bg-pink-500 hover:text-black bg-slate-950/50': open }"
  aria-label="Toggle Navigation"
  (click)="toggleMenu()"
>
  <svg
    aria-hidden="true"
    class="h-3.5 w-3.5 overflow-visible stroke-white"
    fill="none"
    strokeWidth="2"
    strokeLinecap="round"
  >
    <path d="M0 1H14M0 7H14M0 13H14" class="origin-center transition {{ open ? 'scale-90 opacity-0' : '' }}" />
    <path d="M2 2L12 12M12 2L2 12" class="origin-center transition {{ !open ? 'scale-90 opacity-0' : '' }}" />
  </svg>
</button>

<div #mobileMenuOverlay class="fixed right-0 top-0 bottom-0 w-1/2 bg-slate-950/70 transition duration-150 text-white" [ngClass]="{
  'opacity-100 translate-x-0 ease-out': open,
  'opacity-0 translate-x-full ease-in': !open,
  }">
  <div class="flex flex-col text-right pt-32 px-2">
    <a routerLink="/" csScrollTo="#testimonials" (click)="toggleMenu()" class="block w-full py-1 px-4 rounded-full hover:bg-pink-500 hover:text-white">Testimonials</a>
    <a routerLink="/" csScrollTo="#pricing" (click)="toggleMenu()" class="block w-full py-1 px-4 rounded-full hover:bg-pink-500 hover:text-white">Pricing</a>
    <a routerLink="/" csScrollTo="#faq" (click)="toggleMenu()" class="block w-full py-1 px-4 rounded-full hover:bg-pink-500 hover:text-white">FAQs</a>
    <hr class="my-3 mx-1 border-white/40" />
    <a *ngIf="isAuthenticated | async" routerLink="/logout" class="block w-full py-1 px-4 rounded-full hover:bg-pink-500 hover:text-white">Sign out</a>
    <a *ngIf="(isAuthenticated | async) !== true" routerLink="/login" class="block w-full py-1 px-4 rounded-full hover:bg-pink-500 hover:text-white">Sign in</a>
  </div>
</div>
