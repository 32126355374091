import type Environment from './environment.model';

const environment: Environment = {
  production: true,
  name: 'prod',
  api: {
    baseUrl: 'https://casualse.xyz/api',
  },
  appInsights: {
    appId: 'casuals-web',
    connectionString:
      'InstrumentationKey=ee10800e-9310-45d1-9ea7-6dd0b23d742b;IngestionEndpoint=https://australiaeast-1.in.applicationinsights.azure.com/;LiveEndpoint=https://australiaeast.livediagnostics.monitor.azure.com/',
  },
};

export default environment;
