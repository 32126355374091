<section id="faq" aria-labelledby="faq-title" class="relative overflow-hidden pb-2 pt-20 sm:pt-32">
  <cs-container classes="relative">
    <div class="mx-auto max-w-2xl lg:mx-0">
      <h2 id="faq-title" class="font-display text-3xl tracking-tight text-pink-300 sm:text-4xl">
        Frequently asked questions
      </h2>
      <p class="mt-4 text-2xl font-light tracking-tight text-pink-50">
        If you can't find what you're looking for, email our support team and if you're lucky someone will get back to
        you.
      </p>
    </div>
    <ul role="list" class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3">
      <li *ngFor="let column of faqs; index as columnIndex" id="c-{{ columnIndex }}">
        <ul role="list" class="flex flex-col gap-y-8">
          <li *ngFor="let faq of column; index as faqIndex" id="f-{{ faqIndex }}">
            <h3 class="text-lg leading-7 text-pink-200">{{ faq.question }}</h3>
            <p class="mt-4 text-md text-pink-50" [innerHTML]="faq.answer"></p>
          </li>
        </ul>
      </li>
    </ul>
  </cs-container>
</section>
