<section id="testimonials" aria-label="What our customers are saying" class="py-20 sm:py-32">
  <cs-container>
    <div class="mx-auto max-w-2xl md:text-center">
      <h2 class="font-display text-4xl tracking-tight sm:text-5xl">Casuals community</h2>
      <p class="mt-4 text-2xl font-light tracking-tight text-pink-100">
        We value our community's happiness, wellbeing and excitement, <br>and we would love for you to join in the fun.
      </p>
    </div>
    <ul
      role="list"
      class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-6 sm:gap-8 lg:mt-20 lg:max-w-none lg:grid-cols-3"
    >
      <li *ngFor="let column of testimonials; index as columnIndex" id="c-{{ columnIndex }}">
        <ul role="list" class="flex flex-col gap-y-6 sm:gap-y-8">
          <li *ngFor="let testimonial of column; index as testimonialIndex" id="t-{{ testimonialIndex }}">
            <figure class="relative rounded-2xl bg-black/50 p-6 shadow-xl shadow-black/10">
              <cs-quote-icon class="absolute left-6 top-6 fill-pink-950/80" />
              <blockquote class="relative">
                <p class="text-lg tracking-tight text-pink-200">{{ testimonial.content }}</p>
              </blockquote>
              <figcaption class="relative mt-6 flex items-center justify-between border-t border-pink-300/50 pt-6">
                <div>
                  <div class="text-base text-pink-200">{{ testimonial.author.name }}</div>
                  <div class="mt-1 text-sm text-pink-500">Member since {{ testimonial.author.joined }}</div>
                </div>
                <div class="overflow-hidden rounded-full bg-pink-50">
                  <img class="h-14 w-14 object-cover" [src]="testimonial.author.image" alt="" width="56" height="56" />
                </div>
              </figcaption>
            </figure>
          </li>
        </ul>
      </li>
    </ul>
  </cs-container>
</section>
