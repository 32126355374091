<section id="find-a-partner" aria-label="Find a partner"
class="relative overflow-hidden pb-28 pt-20 sm:py-32"
>
<cs-container classes="relative">
  <div class="max-w-2xl md:mx-auto md:text-center xl:max-w-none">
    <h2 class="font-display text-4xl tracking-tight text-white sm:text-5xl md:text-6xl shadow-red-600 text-shadow">
      Find a partner
    </h2>
    <p class="mt-6 text-3xl font-thin tracking-tight text-pink-50 shadow-red-600 text-shadow">
      Everyone craves an intimate connection from time to time, find someone who desires to <br class="hidden xl:inline">share an intimate moment together
    </p>
    <button
      csButton
      [routerLink]="(isAuthenticated$ | async) ? '/get-started' : '/login'"
      [queryParams]="{ flow: (isProfileComplete$ | async) ? 'complete' : 'new' }"
      color="white"
      class="mt-10 px-8 text-xl shadow-md hover:shadow-red-600 shadow-red-600/50">
      Get started for free
    </button>
    <p class="mt-3 text-lg text-red-800 font-bold">No strings, just good times</p>
  </div>
</cs-container>
</section>
