<div id="search" class="pt-10 pb-24 max-w-lg relative mx-auto">
  <ng-icon *ngIf="!isLoading" (click)="search()" name="heroMagnifyingGlass" class="text-pink-300 absolute right-3 mx-3 my-1 p-1 cursor-pointer" size="2rem"></ng-icon>
  <cs-spinner *ngIf="isLoading" color="pink" size="6" class="absolute right-0 mx-3 my-2 p-1"></cs-spinner>
  <input
    type="text"
    [matAutocomplete]="auto"
    [(ngModel)]="locationSearch"
    (keyup.enter)="search()"
    class="text-2xl w-full pl-6 pr-16 mb-2 font-light text-pink-300 ring-1 border-none ring-pink-300 active:ring-pink-100 hover:ring-pink-200 focus:ring-pink-200 outline-none bg-pink-950/20 rounded-full" />
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="locationSelected($event)" class="bg-pink-950">
    @for (option of locationSuggestions; track option) {
      <mat-option [value]="option" class="hover:!bg-pink-700 !text-lg">{{formatLocation(option)}}</mat-option>
    }
  </mat-autocomplete>
  <p *ngIf="!error" [class.opacity-100]="currentMembersSearching > 0" class="transition-opacity opacity-0 m-2 text-lg text-pink-300">{{currentMembersSearching}} looking in your area now</p>
  <p *ngIf="error" class="transition-opacity opacity-0 m-2 text-lg text-red-300">There was an error when attempting to search this location. Please wait a moment then try again.</p>
</div>
