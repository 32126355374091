import { CommonModule } from '@angular/common';
import { Component, effect, OnInit, signal, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgIconComponent } from '@ng-icons/core';
import { AuthService } from '../../services/auth.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatAutocomplete, MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { CreateLocationRequest, GetLocationResponse, LocationClient } from '../../services/api.services';
import { SpinnerComponent } from '../spinner/spinner.component';
import { BehaviorSubject, debounceTime, distinctUntilChanged } from 'rxjs';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'cs-search-bar',
  standalone: true,
  imports: [CommonModule, FormsModule, NgIconComponent, MatAutocompleteModule, SpinnerComponent],
  templateUrl: './search-bar.component.html',
  styleUrl: './search-bar.component.scss'
})
export class SearchBarComponent implements OnInit {
  @ViewChild('auto') auto!: MatAutocomplete;

  error: Error | undefined;
  isLoading = false;
  currentMembersSearching = 0; //TODO calculate number of members searching in real time
  locationSearch = signal('');
  locationSuggestions: GetLocationResponse[] = [];
  locationId = '';
  locationSlug = '';

  constructor(private router: Router, private authService: AuthService, private locationClient: LocationClient) {
    this.authService.userProfile$.pipe(takeUntilDestroyed()).subscribe(user => {
      this.locationId = user?.locationId || '';
      this.locationSlug = user?.locationSlug || '';
      const locationName = user?.locationName || '';
      this.locationSearch.set(locationName); //fallback to location by IP, or the location of the random hotpot

      //TODO remove simulated test data
      if (locationName) {
        setTimeout(() => { this.currentMembersSearching = 15; }, 2000);
        setInterval(() => { this.currentMembersSearching = Math.floor(Math.random() * 100); }, 60000);
      }
    });


    const locationSearch$ = new BehaviorSubject('');
    locationSearch$.pipe(debounceTime(250), distinctUntilChanged(), takeUntilDestroyed()).subscribe({
      next: (search) => {
        if (!search || search.length < 2) return;
        this.isLoading = true;

        this.locationClient.list(0, 10, "", search, "").subscribe({
          next: (result) => {
            if (result.items && result.items.length) {
              const items = result.items;
              this.locationSuggestions = items;
            }
          },
          error: (error) => {
            console.error('Failed to search for locations', error);
            this.error = error;
          },
          complete: () => {
            this.isLoading = false;
          }
        });
      }
    });

    effect(() => locationSearch$.next(this.locationSearch()));
  }

  search() {
    if (this.locationSlug) {
      this.router.navigate(['/search'], { queryParams: { slug: this.locationSlug } });
    }
    else {
      this.router.navigate(['/search'], { queryParams: { q: this.locationSearch() } });
    }
  }

  locationSelected(event: MatAutocompleteSelectedEvent) {
    this.isLoading = true;
    this.error = undefined;
    const guidEmpty = '00000000-0000-0000-0000-000000000000';
    const selectedLocation = event.option.value as GetLocationResponse;
    this.locationSearch.set(`${selectedLocation.city}, ${selectedLocation.state}, ${selectedLocation.country}`);
    if (selectedLocation.id && selectedLocation.id !== guidEmpty) {
      this.locationId = selectedLocation.id!;
      this.locationSlug = selectedLocation.slug || ''; //used in the completed UI 'start search' call-to-action
      this.search();
    }
    else if (selectedLocation.googlePlaceId) {
      this.locationClient.create(new CreateLocationRequest({
        googlePlaceId: selectedLocation.googlePlaceId
      })).subscribe({
        next: (createdLocation) => {
          this.locationId = createdLocation.id!;
          this.locationSlug = createdLocation.slug || ''; //used in the completed UI 'start search' call-to-action
          this.search();
        },
        error: (error) => {
          console.error('Failed to create location', error);
          this.error = error;
          this.isLoading = false;
        }
      });
    }
  }

  formatLocation(location: GetLocationResponse) {
    let description = location.city;
    if (location.state) description += `, ${location.state}`;
    if (location.country) description += `, ${location.country}`;
    return description;
  }

  //TODO remove simulated test data
  ngOnInit(): void {
  }

}
