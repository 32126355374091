import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroMagnifyingGlass } from '@ng-icons/heroicons/outline';
import { ContainerComponent } from '../container/container.component';
import { ButtonDirective } from '../../directives/button.directive';
import { SearchBarComponent } from '../search-bar/search-bar.component';
import { HotspotsComponent } from '../hotspots/hotspots.component';

@Component({
  selector: 'cs-hero',
  standalone: true,
  imports: [CommonModule, ContainerComponent, RouterLink, ButtonDirective, NgIconComponent, SearchBarComponent, HotspotsComponent],
  providers: [provideIcons({ heroMagnifyingGlass })],
  templateUrl: './hero.component.html',
  styleUrl: './hero.component.scss',
})
export class HeroComponent {
  constructor() {}
}
